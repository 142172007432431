<template>
  <div class="slider">
    <h3>{{ title }}</h3>

    <div class="slider-container">
      <div ref="slider" class="keen-slider">
        <div class="keen-slider__slide" v-for="(img, i) in images" :key="img">
          <img @click="goTo(i)" :src="mediaPrefix + img">
        </div>

        <span v-if="slider" @click="slider.prev()" :class="{
          icon: true,
          '-prev': true,
          'icon-chevron': true,
          '-disabled': current === 0,
        }" />
        <span v-if="slider" @click="slider.next()" :class="{
          icon: true,
          '-next': true,
          'icon-chevron': true,
          '-disabled': current === slider.track.details.slides.length - 1,
        }" />
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
