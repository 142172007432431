import ViewMixin from '../../js/viewMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'settings',
  mixins: [ViewMixin],
  data () {
    return {
      password: '',
      oldPassword: '',
      firstname: '',
      lastname: '',
      email: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },
  methods: {
    ...mapActions('auth', {
      newPassword: 'newPassword'
    }),
    ...mapActions('auth', {
      updateUser: 'updateUser'
    }),
    handleLogin () {
      this.newPassword({
        password: this.password,
        oldPassword: this.oldPassword
      })
    },
    handleChangeUser () {
      const confirm = window.confirm('Attention, si vous changez ces infos, elles seront utilisées pour se connecter à la plateforme. Souhaitez-vous continuer ?')
      if (!confirm) return

      this.updateUser({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email
      })
    }
  },
  beforeMount () {
    this.firstname = this.user.firstname
    this.lastname = this.user.lastname
    this.email = this.user.email
  }
}
