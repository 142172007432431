<template>
    <div class="view reports">
        <Heading>{{ $t('my-reports') }}</Heading>
        <ul>
            <li v-for="(item, key) in reportsByMonthPlane" :key="key">
                <ReportItem :content="item" />
            </li>
        </ul>
    </div>
</template>

<script src="./script.js"></script>
<!-- <style src="./style.less" lang="less"></style> -->
