<template>
  <div class="view settings">

    <div class="content">
      <section class="column">
        <h2>{{ $t('account-settings') }}</h2>

        <div class="form-group">
          <div class="input-block">
            <label for="firstname">{{ $t('first-name') }}</label>
            <input type="text" name="firstname" v-model="firstname">
          </div>
          <div class="input-block">
            <label for="lastname">{{ $t('last-name') }}</label>
            <input type="text" name="lastname" v-model="lastname">
          </div>
        </div>
        <div class="input-block">
          <label for="email">{{ $t('email') }}</label>
          <input type="email" name="email" v-model="email">
        </div>
        <!-- <div class="input-block">
          <label for="public_name">{{ $t('public-name') }}</label>
          <input type="text" name="public_name">
        </div>
        <span class="infos">Specifies how your name will appear in the account section and in notices</span> -->
        <button @click="handleChangeUser">
          save
        </button>
      </section>
      <section class="column">
        <h2>{{ $t('changing-password') }}</h2>
        <div class="input-block">
          <label for="current_password">{{ $t('actual-password') }}</label>
          <input type="password" name="current_password" v-model="oldPassword">
        </div>
        <div class="input-block">
          <label for="new_password">{{ $t('new-password') }}</label>
          <input type="password" name="new_password" v-model="password">
        </div>
        <div class="input-block">
          <label for="new_password_confirm">{{ $t('confirm-password') }}</label>
          <input type="password" name="new_password_confirm">
        </div>
        <button @click="handleLogin">{{ $t('save') }}</button>
      </section>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
