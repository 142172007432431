<template>
  <div class="switch-lang">
    <select class="switch-lang__select" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SwitchLang',
  data () {
    return { langs: ['en', 'fr'] }
  },
  watch: {
    '$i18n.locale' (val) {
      localStorage.setItem('locale', val)
    }
  }
}
</script>

<style lang="less" scoped>
.switch-lang {
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 10px;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    background-color: fade(#fff, 10%);
  }

  &__select {
    padding: 8px 0 8px 8px;

    background-color: transparent;
    color: #fff;
    text-transform: uppercase;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
</style>
