import Logo from '../../assets/skyfirst-logo.png'
import SwitchLang from '../SwitchLang/index.vue'
import { mapActions } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    SwitchLang
  },
  data: () => {
    return {
      logo: Logo
    }
  },
  methods: {
    ...mapActions('auth', {
      logout: 'logout'
    }),
    async _logout () {
      await this.logout()
        .then(() => {
          this.$router.push({ path: '/' })
        })
    }
  }
}
