export default {
  "my-briefing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Briefing"])},
  "account-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du Compte"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
  "see-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
  "my-aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Avion"])},
  "my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Détails Financiers"])},
  "my-flights-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Détails de Vols"])},
  "my-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Rapports"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "flight-calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier des Vols"])},
  "flight-crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipage de Vol"])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
  "see-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
  "financial-position-of-the-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position Financière des Mois"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "current-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois en cours"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "last-month-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat du mois dernier"])},
  "see-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la facture"])},
  "year-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l'année"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itinéraire"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
  "selling-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de Vente"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût"])},
  "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge"])},
  "leg-infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos du Segment"])},
  "fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburant"])},
  "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur"])},
  "route-taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes de Route"])},
  "handling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance au Sol"])},
  "catering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering"])},
  "crew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipage"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf"])},
  "€": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "operators-remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque de l'Opérateur"])},
  "monthly-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat Mensuel"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "my-flight-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Détails de Vol"])},
  "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "my-aircraft-insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Assurance Avion"])},
  "active-programs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmes Actifs"])},
  "print-my-financial-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer mes détails financiers"])},
  "the-data-is-empty-for-this-month-please-select-another-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont vides pour ce mois, veuillez en sélectionner un autre"])},
  "account-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du Compte"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "public-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom public"])},
  "specifies-how-your-name-will-appear-in-the-account-section-and-in-notices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifie comment votre nom apparaîtra dans la section du compte et dans les notifications"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "changing-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
  "actual-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])}
}