<template>
  <div :class="[{ unseen: content.files.find(report => report.unseen === true) !== undefined }, 'report-item']">
    <div :class="[{open: open}, 'report-item-maincontent']" ref="main">
      <div>
        <div class="report-item-date">
          <span>
            <h4 v-if="isMobile">{{ $t('month') }}</h4>
            {{ content.date.format("MMM") }}
          </span>
          <span>
            <h4 v-if="isMobile">{{ $t('year') }}</h4>
            {{ content.date.format("YYYY") }}
          </span>
        </div>
        <h4>{{ $t('operators-remark') }}</h4>
        <div class="report-item-remark">{{ content.remark }}</div>
      </div>
      <div>
        <h4>{{ $t('monthly-result') }}</h4>
        <div class="report-item-result">{{ intFormater(content.result) }} €</div>
      </div>
    </div>
    <div class="report-item-subcontent" ref="sub">
      <div class="container-sub">
        <template v-for="(report, key) in content.files" :key="key">
          <div class="report-item-subcontent-card">
            <Card :infos="report" />
          </div>
        </template>
        <div>
          <button class="report-item-link" @click="openAll">{{ $t('see-all') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
