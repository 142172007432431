import FlightDetails from '@/components/FlightDetails'
import FinancialList from '@/components/FinancialList'
import FinancialTable from './FinancialTable'
import FromTo from '@/components/FromTo'
import ViewMixin from '@/js/viewMixin'
import GraphPie from '@/components/GraphPie'
import GraphLine from '@/components/GraphLine'

import { mapGetters } from 'vuex'
import _ from 'lodash'
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default {
  name: 'financial',
  components: {
    FinancialTable,
    FlightDetails,
    FinancialList,
    FromTo,
    GraphPie,
    GraphLine
  },
  mixins: [ViewMixin],
  data: () => {
    return {
      flightOpen: false,
      costRepartitionFixed: {
        title: 'Fixed costs'
      },
      costRepartitionVariable: {
        title: 'Variable costs'
      }
    }
  },
  mounted () {
  },

  computed: {
    ...mapGetters('missions', {
      missions: 'missions'
    }),
    ...mapGetters('financialDetails', {
      financialDetailsByMonth: 'financialDetailsByMonth'
    }),
    ...mapGetters('datepicker', {
      from: 'from',
      to: 'to'
    }),
    financialDetailsSelectedPeriod () {
      const reports = this.getYearMonthReportIndex(this.from, this.to)
      return {
        statements: this.formatRangeStatements(reports),
        statementsTotal: this.formatRangeStatementsTotal(reports),
        reports: reports
      }
    },
    financialDetailsV2 () {
      const dataToFilter = this.financialDetailsByMonth

      // get list of reports
      const reports = this.getYearMonthReportIndex(this.from, this.to)

      // filter DATA
      const DATA = Object.keys(dataToFilter).filter(key => reports.includes(key)).reduce((acc, key) => {
        acc[key] = dataToFilter[key]
        return acc
      }, {})

      const lengthData = Object.keys(DATA).length

      if (lengthData === 0) return null

      function sumForMonth (path, type = null) {
        const isTime = type === 'time'

        const d = Object.values(DATA).reduce((acc, curr) => {
          const value = _.get(curr, path, 0)
          // if is Time && value is not a number
          if (isTime && isNaN(value)) {
            const [hours, minutes] = value.split(':')
            return acc + (parseInt(hours) * 60) + parseInt(minutes)
          }
          // return
          return acc + value
        }, 0)

        switch (type) {
          case 'time':
            return `${Math.floor(d / 60)}h${(d % 60).toString().padStart(2, '0')}`
          case 'percentage':
            return (d / lengthData).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          default:
            return d.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      }

      const hoursFlight = {
        columns: [''],
        list: [
          {
            name: 'Number of flight hours',
            datas: [
              {
                name: 'Sales flight hours',
                values: [sumForMonth('data.details.flightTime.sales.month', 'time')]
              },
              {
                name: 'Owner flight hours',
                values: [sumForMonth('data.details.flightTime.owner.month', 'time')]
              },
              {
                name: 'Total',
                values: [sumForMonth('data.details.flightTime.total.month', 'time')]
              }
            ]
          }
        ]
      }

      const turnover = {
        columns: ['€', '/hdv'],
        columnsSign: [' €', ''],
        list: [
          {
            name: DATA[Object.keys(DATA)[0]].data.details.turnover.title,
            datas: DATA[Object.keys(DATA)[0]].data.details.turnover.entries.map((e, i) => {
              return {
                name: e.name,
                values: [
                  sumForMonth(`data.details.turnover.entries[${i}].month`),
                  sumForMonth(`data.details.turnover.entries[${i}].perHour`)
                ]
              }
            })
          }
        ]
      }

      const costs = {
        columns: ['€', '/hdv', '%'],
        columnsSign: [' €', '', ' %'],
        list: DATA[Object.keys(DATA)[0]].data.details.details.map((el, index) => {
          return {
            name: el.title,
            datas: el.entries.map((e, i) => {
              return {
                name: e.name,
                values: [
                  sumForMonth(`data.details.details[${index}].entries[${i}].month`),
                  sumForMonth(`data.details.details[${index}].entries[${i}].perHour`),
                  sumForMonth(`data.details.details[${index}].entries[${i}].percentage`, 'percentage')
                ]
              }
            })
          }
        })
      }

      const costTotalList = {
        name: 'Total cost',
        values: costs.list.map((el, index) => {
          // return last value of each list
          return el.datas[el.datas.length - 1].values
        }).reduce((acc, curr) => {
          // sum each value
          return acc.map((el, index) => {
            const nb = isNaN(curr[index]) ? Number(curr[index].replace(',', '')) : Number(curr[index])
            return (Number(el) || 0) + nb
          })
        }, [0, 0, 0]).map(str => str.toLocaleString('fr', { minimumFractionDigits: 2 }))
      }
      costs.totalList = costTotalList

      const total = {
        name: 'Operation result',
        value: sumForMonth('data.details.total.month')
      }

      const returned = {
        hoursFlight,
        turnover,
        costs,
        total
      }

      //
      // set entries for each list
      //
      Object.values(DATA).forEach((el, index) => {
        // for each entries
        Object.values(el.data.report.accounts).forEach((e, i) => {
          let name = e.accountName.replace('  ', ' ')
          if (name === 'Wages Captain') name = 'Wages Pilots'
          else if (name === 'Scheduled Maintenance') name = 'Maintenance'
          else if (name === 'Unscheduled Maintenance') name = 'Maintenance'
          else if (name === 'Aircraft Technical Docum & program') name = 'Aircraft Technical Documentation & program'
          else if (name.includes('Overflight')) name = 'Overflight'

          // if returned child has list with name in datas
          const selectData = [...returned.hoursFlight.list, ...returned.turnover.list, ...returned.costs.list]
            .reduce((acc, list) => acc.concat(list.datas), [])
            .find(el => name === 'Maintenance' ? el.name === 'Maintenance' : el.name.includes(name))

          if (selectData) {
            if (selectData.entries) {
              selectData.entries.push(...e.entries)
            } else {
              selectData.entries = e.entries || []
            }
          } else {
            console.error('not found :', name, i, selectData)
          }
        })
      })

      return returned
    },
    fixedCosts () {
      let costs = {}
      const reports = this.getYearMonthReportIndex(this.from, this.to)
      const total = this.formatRangeFixed(reports)
      const detailsTotal = this.formatRangeFixedTotal(reports)
      if (total) {
        costs = Object.keys(total)?.map(entry => {
          if (total[entry].name === 'TOTAL FIXED COSTS') return {}
          const data = {}
          data.value = Math.round(total[entry].total / detailsTotal * 100)
          data.label = total[entry].name
          data.ammout = this.formatPriceDisplay(total[entry].total)
          data.perHour = total[entry].perHourDisplay
          return data
        })
      }
      const filteredArray = costs?.filter(value => Object.keys(value).length !== 0 && value.value !== 0)
      return {
        ...this.costRepartitionFixed,
        data: filteredArray || null,
        total: {
          title: 'Cout fixe',
          val: detailsTotal
        }
      }
    },
    variableCosts () {
      let costs = {}
      const reports = this.getYearMonthReportIndex(this.from, this.to)
      const total = this.formatRangeFixed(reports, true)
      const detailsTotal = this.formatRangeFixedTotal(reports, true)
      if (total) {
        costs = Object.keys(total)?.map(entry => {
          if (total[entry].name === 'TOTAL VARIABLE COSTS') return {}
          const data = {}
          data.value = Math.round(total[entry].total / detailsTotal * 100)
          data.label = total[entry].name
          data.ammout = this.formatPriceDisplay(total[entry].total)
          data.perHour = total[entry].perHourDisplay
          return data
        })
      }
      const filteredArray = costs?.filter(value => Object.keys(value).length !== 0 && value.value !== 0)
      return {
        ...this.costRepartitionVariable,
        data: filteredArray,
        total: {
          title: 'Cout Variable',
          val: detailsTotal
        }
      }
    }
  },
  methods: {
    openFlightDetails () {
      this.flightOpen = true
    },
    closeFlightDetails () {
      this.flightOpen = false
    },
    getYearMonthReportIndex (from, to) {
      const data = []

      // from format MMM YYYY
      // to format MMM YYYY
      const fromMonthIndex = months.indexOf(from.split(' ')[0])
      const fromYear = from.split(' ')[1]

      const toMonthIndex = months.indexOf(to.split(' ')[0])
      const toYear = to.split(' ')[1]

      // generate array of date format YYYYMM
      for (let i = fromYear; i <= toYear; i++) {
        const start = i === fromYear ? fromMonthIndex : 0
        const end = i === toYear ? toMonthIndex : 11
        for (let j = start; j <= end; j++) {
          data.push(`${i}${j < 10 ? '0' : ''}${j + 1}`)
        }
      }

      // filter if financialDetailsByMonth has key
      return data.filter(key => Object.keys(this.financialDetailsByMonth).includes(key))
    },
    formatRangeStatements (statements) {
      const data = {} // tmp object
      let nbDetails = 0 // number of actual reports
      if (statements.length === 0) return
      // loop through range of dates
      statements.forEach((statement) => {
        // check if report exist
        if (this.financialDetailsByMonth[statement]) {
          // increment real number of reports
          nbDetails++
          // loop through statements
          this.financialDetailsByMonth[statement].data.statements.forEach((obj) => {
            // populate tmp object with entries
            data[obj.title] = { title: obj.title, entries: obj.entries.concat(data[obj.title]?.entries) }
          })
        }
      })

      Object.keys(data).forEach((key) => {
        // tmp object to have all entries of statement
        const _entries = {}
        data[key].entries.forEach(element => {
          if (element) {
            // tmp amount
            const amount = _entries[element.name] ? _entries[element.name].amount : 0
            _entries[element.name] = { name: element.name, amount: amount + element.amount }
          }
        })
        // Monthly report, so divided by number of actual reports
        if (_entries['Monthly operating result']) {
          _entries['Monthly operating result'].amount /= nbDetails
        }
        // Monthly report, so divided by number of actual reports
        if (_entries['Monthly fees']) {
          _entries['Monthly fees'].amount /= nbDetails
        }
        // clean real data
        data[key].entries = []
        Object.keys(_entries).forEach((e) => {
          // populate real data + add display
          data[key].entries.push({ ..._entries[e], amountDisplay: this.formatPriceDisplay(_entries[e].amount) })
        })
      })
      if (nbDetails === 0) {
        return null
      }
      return data
    },
    formatRangeStatementsTotal (reports) {
      let total = 0
      reports.forEach((report) => {
        if (this.financialDetailsByMonth[report]) {
          let displayAmount = this.financialDetailsByMonth[report].data?.statementsTotal.match(/(.*) €/)[1]
          if (displayAmount.startsWith('(')) {
            displayAmount = displayAmount.replace(/\(/g, '')
            total -= parseFloat(displayAmount.replace(/,/g, ''))
          } else {
            total += parseFloat(displayAmount.replace(/,/g, ''))
          }
        }
      })
      return this.formatPriceDisplay(total)
    },
    formatRangeFixedTotal (reports, variable = false) {
      let total = 0
      reports.forEach((report) => {
        if (this.financialDetailsByMonth[report]) {
          const _total = this.financialDetailsByMonth[report].data?.details?.details.find(detail => variable ? detail.title === 'VARIABLE COSTS' : detail.title === 'FIXED COSTS')
          total += _total?.entries.find(detail => variable ? detail.name === 'TOTAL VARIABLE COSTS' : detail.name === 'TOTAL FIXED COSTS').total
        }
      })
      return total
    },
    formatRangeFixed (reports, variable = false) {
      const data = {} // tmp object
      if (reports.length === 0) return
      reports.forEach((report) => {
        if (this.financialDetailsByMonth[report]) {
          const fixed = this.financialDetailsByMonth[report]?.data?.details?.details.find(detail => variable ? detail.title === 'VARIABLE COSTS' : detail.title === 'FIXED COSTS')
          fixed.entries.forEach((obj) => {
            let _total = data[obj.name] ? data[obj.name].total : 0
            let _month = data[obj.name] ? data[obj.name].month : 0
            let _hour = data[obj.name] ? data[obj.name].hour : 0
            data[obj.name] = {
              name: obj.name,
              total: _total += obj.total,
              month: _month += obj.month,
              hour: _hour += obj.hour
            }
          })
        }
      })
      Object.keys(data).forEach((key) => {
        data[key].perHourDisplay = this.formatPriceDisplay(data[key].hour)
        data[key].monthDisplay = this.formatPriceDisplay(data[key].month)
      })
      return data
    },
    formatPriceDisplay (price, symbol = false) {
      const isNegative = parseFloat(price) < 0
      const formattedPrice = parseFloat(parseFloat(price * (isNegative ? -1 : 1)).toFixed(2)).toLocaleString('en')
      const integerPart = formattedPrice.split('.')[0] || 0
      const decimalPart = (formattedPrice.split('.')[1] || '').padEnd(2, '0')
      return `${isNegative ? '(' : ''}${integerPart}.${decimalPart}${symbol ? ' €' : ''}${isNegative ? ')' : ''}`
    },

    printPage () {
      window.print()
    }
  }
}
